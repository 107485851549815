<template>
    <div class="panel land-acknowledgement-modal modal white" id="modal">
        <h2>How do we drive impact as a leading art and design university in a world in flux?</h2>
        <p>OCAD University is a community of bold, curious and compassionate artists, designers and scholars who are imagining and creating a joyful, equitable and sustainable world. This is the vision we have set out in our integrated Academic and Strategic Plan—one that is about transforming people’s lives by what we do.</p>
        <p>As you navigate through this six-year plan, you’ll discover seven priorities with corresponding objectives that interconnect with this vision and take into consideration the current context in which we live. These speak to creating space to facilitate and nurture the growth and success of the next-generation of artists, designers and scholars as they tackle some of the most complex challenges of our time.</p>
        <p>From designing for climate resilience to reshaping our relationship with technology, from tackling inequality to preserving democratic practices—this plan sets the course for these creative leaders to be resilient and flourish as they co-create equitable, just and sustainable futures for all.</p>
        <p>We want to thank everyone in our community—our Board members, students, faculty, staff, alumni and partners—for their contributions and participation in our year-long co-creation planning exercise. We’re excited and energized by the creative possibilities and opportunities for collaboration, innovation and experimentation that will emerge over the next six years.</p>
        <p>While the world around us will continue to shift and change, OCAD U’s Academic and Strategic Plan provides us with clear direction and focus to lead the way for us to drive impact as a leader in art and design education.</p>

        <ul class="columns">
          <li><img src="../assets/img/ana.jpg" alt="" role="presentation" loading="lazy" /><br>
            <span>
              <strong>Ana Serrano</strong>
              President and Vice-Chancellor<br />
              Chair, Strategic Planning Committee
            </span>
          </li>
          
          <li><img src="../assets/img/caroline.jpg" alt="" role="presentation" loading="lazy" /><br>
            <span>
              <strong>Dr. Caroline Langill</strong>
              Vice-President, Academic and Provost<br />
              Chair, Academic Planning Committee
            </span>
          </li>
        </ul>

        <p class="button" id="close" style="text-align: center;"><a class="button circle" href="#">Enter Site</a></p>
    </div>
    <div id="shadow"></div>
</template>

<script>
export default {
  name: 'LandAcknowledgementView',
  components: {
  },
  created() {
    console.log('created!')
  },
    mounted() {
    console.log('mounted!');
    this.$nextTick(function() {
      console.log('nextTick!')
      this.showModal()
    })
  },
  updated() {
    console.log('updated!');
  },
  methods: {
    showModal() {
      const popup = sessionStorage.getItem('showModal')
      const modal = document.getElementById('modal')
      const shadow = document.getElementById('shadow')

     if (!popup) {
        document.getElementById('close').addEventListener("click", (e) => {
          e.preventDefault()
          sessionStorage.setItem('showModal', true)
          const fadeEffect = setInterval(function() {
              if (!modal.style.opacity) {
                modal.style.opacity = 1
                shadow.style.opacity = 1
              }
              if (modal.style.opacity > 0) {
                modal.style.opacity -= 0.1
                shadow.style.opacity -= 0.1
              } else {
                clearInterval(fadeEffect);
                modal.remove()
                shadow.remove()
              }
          }, 50);
        });
      } else {
        modal.remove()
        shadow.remove()
      }
    }
  }
}

</script>

<style scoped lang="scss">

.flex {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
}

#shadow {
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    z-index: 998;
    position: fixed;
    top: 0;
}

#modal {
    width: 90vw;
    height: 85vh;
    margin: 5%;
    padding: 5%;
    position: fixed;
    z-index: 999;
    box-shadow: none;
    top: 0;
    overflow: scroll;
  h2 {
      margin: 0 0 2rem 0;
      font-size: 1.95rem;
      line-height: 2.25rem;
  }
}

ul.columns li {
  border: none;
  
  img {
    width: 100%;
    max-width: 200px;
  }

}
</style>