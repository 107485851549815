<template>
  <nav class="navbar">
    <div class="inner">
      <div id="logo">
        <router-link to="/"><svg viewBox="0 0 85 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-1.63185e-06 26L7.53128 26L9.37718 21.3901L19.3451 21.3901L21.2279 26L28.9068 26L17.9053 1.1238e-06L11.0016 6.90498e-07L-1.63185e-06 26ZM11.4815 15.8213L14.398 8.48227L17.2776 15.8213L11.4815 15.8213Z" fill="#111111"/>
          <path d="M27.7278 13.2561C27.7278 20.8825 31.5469 25.9519 37.972 25.9519L38.0619 25.9519C43.9479 25.9519 46.2843 21.4657 47.8569 14.243C48.8453 9.89148 49.4744 8.81481 50.9122 8.81481L51.002 8.81481C52.1253 8.81481 53.0239 9.84662 53.0239 11.9551C53.0239 14.7365 51.4408 20.4612 47.8568 25.9519L55.8628 25.9519C58.6036 22.5424 60.2578 17.5179 60.2578 12.1794C60.2578 4.6427 56.0343 0.0219733 50.1933 0.021973L50.1034 0.021973C43.6333 0.0219727 41.7462 5.18103 40.2186 11.8654C39.2301 16.1272 38.5112 17.159 37.2082 17.159L37.1183 17.159C35.7704 17.159 34.9616 15.9029 34.9616 13.5701C34.9616 9.93634 36.9908 3.11741 39.462 0.0219721L32.0053 0.021973C29.2018 4.34822 27.7278 7.69327 27.7278 13.2561Z" fill="#111111"/>
          <path d="M62.8203 25.9655L69.9767 25.9655L69.9767 18.583L73.518 18.583C79.9367 18.583 84.6216 15.3717 84.6216 9.28117L84.6216 9.20734C84.6216 3.48596 80.4162 0.126954 73.8869 0.126954L62.8203 0.126953L62.8203 25.9655ZM69.9767 12.9724L69.9767 6.14364L73.3336 6.14364C75.9158 6.14364 77.4651 7.32483 77.4651 9.53955L77.4651 9.61338C77.4651 11.6435 75.9158 12.9724 73.3705 12.9724L69.9767 12.9724Z" fill="#111111"/>
        </svg></router-link>
      </div>
      <p class="button"><a @click="pause" class="pause button" id="pause-button" title="Pause/Play Animations">||</a><a class="close button circle menu">Menu</a></p>
    </div>
  </nav>
  <nav class="full" id="menu">
    <p class="button"><a class="close button circle menu">Close</a></p>
    <div class="graphic">
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.75" filter="url(#filter0_d_2054_95117)">
      <rect width="812.461" height="994.595" transform="matrix(0.99996 0.00891083 -0.00885973 0.999961 -154.865 -20.8931)" fill="#EECD22"/>
      </g>
      <mask id="mask0_2054_95117" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-164" y="-21" width="822" height="1003">
      <rect width="812.461" height="994.595" transform="matrix(0.999958 0.00915911 -0.00861957 0.999963 -154.865 -20.8929)" fill="#F37148"/>
      </mask>
      <g mask="url(#mask0_2054_95117)">
      <g opacity="0.5">
      <rect x="-30.4163" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 929.042)" stroke="white"/>
      <rect x="-30.4163" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 860.013)" stroke="white"/>
      <rect x="-30.4163" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 790.984)" stroke="white"/>
      <rect x="-30.4163" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 721.955)" stroke="white"/>
      <rect x="-30.4163" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 652.925)" stroke="white"/>
      <rect x="-30.4163" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 583.896)" stroke="white"/>
      <rect x="-30.4163" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 514.867)" stroke="white"/>
      <rect x="-30.4163" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 445.838)" stroke="white"/>
      <rect x="-30.4163" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 376.809)" stroke="white"/>
      <rect x="-30.4163" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 307.78)" stroke="white"/>
      <rect x="-30.4163" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 238.751)" stroke="white"/>
      <rect x="-30.4163" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 169.722)" stroke="white"/>
      <rect x="-30.4163" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 100.693)" stroke="white"/>
      <rect x="-30.4163" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 -30.4163 31.6636)" stroke="white"/>
      <rect x="32.0999" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 929.042)" stroke="white"/>
      <rect x="32.0999" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 860.013)" stroke="white"/>
      <rect x="32.0999" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 790.984)" stroke="white"/>
      <rect x="32.0999" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 721.955)" stroke="white"/>
      <rect x="32.0999" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 652.925)" stroke="white"/>
      <rect x="32.0999" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 583.896)" stroke="white"/>
      <rect x="32.0999" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 514.867)" stroke="white"/>
      <rect x="32.0999" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 445.838)" stroke="white"/>
      <rect x="32.0999" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 376.809)" stroke="white"/>
      <rect x="32.0999" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 307.78)" stroke="white"/>
      <rect x="32.0999" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 238.751)" stroke="white"/>
      <rect x="32.0999" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 169.722)" stroke="white"/>
      <rect x="32.0999" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 100.693)" stroke="white"/>
      <rect x="32.0999" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 32.0999 31.6636)" stroke="white"/>
      <rect x="94.6162" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 929.042)" stroke="white"/>
      <rect x="94.6162" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 860.013)" stroke="white"/>
      <rect x="94.6162" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 790.984)" stroke="white"/>
      <rect x="94.6162" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 721.955)" stroke="white"/>
      <rect x="94.6162" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 652.925)" stroke="white"/>
      <rect x="94.6162" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 583.896)" stroke="white"/>
      <rect x="94.6162" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 514.867)" stroke="white"/>
      <rect x="94.6162" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 445.838)" stroke="white"/>
      <rect x="94.6162" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 376.809)" stroke="white"/>
      <rect x="94.6162" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 307.78)" stroke="white"/>
      <rect x="94.6162" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 238.751)" stroke="white"/>
      <rect x="94.6162" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 169.722)" stroke="white"/>
      <rect x="94.6162" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 100.693)" stroke="white"/>
      <rect x="94.6162" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 94.6162 31.6636)" stroke="white"/>
      <rect x="157.132" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 157.132 929.042)" stroke="white"/>
      <rect x="157.132" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 157.132 860.013)" stroke="white"/>
      <rect x="157.132" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 157.132 790.984)" stroke="white"/>
      <rect x="157.132" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 157.132 721.955)" stroke="white"/>
      <rect x="157.132" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 157.132 652.925)" stroke="white"/>
      <rect x="157.132" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 157.132 583.896)" stroke="white"/>
      <rect x="157.132" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 157.132 514.867)" stroke="white"/>
      <rect x="157.132" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 157.132 445.838)" stroke="white"/>
      <rect x="157.132" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 157.132 376.809)" stroke="white"/>
      <rect x="157.132" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 157.132 307.78)" stroke="white"/>
      <rect x="157.132" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 157.132 238.751)" stroke="white"/>
      <rect x="157.132" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 157.132 169.722)" stroke="white"/>
      <rect x="157.132" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 157.132 100.693)" stroke="white"/>
      <rect x="157.132" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 157.132 31.6636)" stroke="white"/>
      <rect x="219.648" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 219.648 929.042)" stroke="white"/>
      <rect x="219.648" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 219.648 860.013)" stroke="white"/>
      <rect x="219.648" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 219.648 790.984)" stroke="white"/>
      <rect x="219.648" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 219.648 721.955)" stroke="white"/>
      <rect x="219.648" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 219.648 652.925)" stroke="white"/>
      <rect x="219.648" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 219.648 583.896)" stroke="white"/>
      <rect x="219.648" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 219.648 514.867)" stroke="white"/>
      <rect x="219.648" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 219.648 445.838)" stroke="white"/>
      <rect x="219.648" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 219.648 376.809)" stroke="white"/>
      <rect x="219.648" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 219.648 307.78)" stroke="white"/>
      <rect x="219.648" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 219.648 238.751)" stroke="white"/>
      <rect x="219.648" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 219.648 169.722)" stroke="white"/>
      <rect x="219.648" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 219.648 100.693)" stroke="white"/>
      <rect x="219.648" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 219.648 31.6636)" stroke="white"/>
      <rect x="282.165" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 282.165 929.042)" stroke="white"/>
      <rect x="282.165" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 282.165 860.013)" stroke="white"/>
      <rect x="282.165" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 282.165 790.984)" stroke="white"/>
      <rect x="282.165" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 282.165 721.955)" stroke="white"/>
      <rect x="282.165" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 282.165 652.925)" stroke="white"/>
      <rect x="282.165" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 282.165 583.896)" stroke="white"/>
      <rect x="282.165" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 282.165 514.867)" stroke="white"/>
      <rect x="282.165" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 282.165 445.838)" stroke="white"/>
      <rect x="282.165" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 282.165 376.809)" stroke="white"/>
      <rect x="282.165" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 282.165 307.78)" stroke="white"/>
      <rect x="282.165" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 282.165 238.751)" stroke="white"/>
      <rect x="282.165" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 282.165 169.722)" stroke="white"/>
      <rect x="282.165" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 282.165 100.693)" stroke="white"/>
      <rect x="282.165" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 282.165 31.6636)" stroke="white"/>
      <rect x="344.681" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 344.681 929.042)" stroke="white"/>
      <rect x="344.681" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 344.681 860.013)" stroke="white"/>
      <rect x="344.681" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 344.681 790.984)" stroke="white"/>
      <rect x="344.681" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 344.681 721.955)" stroke="white"/>
      <rect x="344.681" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 344.681 652.925)" stroke="white"/>
      <rect x="344.681" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 344.681 583.896)" stroke="white"/>
      <rect x="344.681" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 344.681 514.867)" stroke="white"/>
      <rect x="344.681" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 344.681 445.838)" stroke="white"/>
      <rect x="344.681" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 344.681 376.809)" stroke="white"/>
      <rect x="344.681" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 344.681 307.78)" stroke="white"/>
      <rect x="344.681" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 344.681 238.751)" stroke="white"/>
      <rect x="344.681" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 344.681 169.722)" stroke="white"/>
      <rect x="344.681" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 344.681 100.693)" stroke="white"/>
      <rect x="344.681" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 344.681 31.6636)" stroke="white"/>
      <rect x="407.197" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 407.197 929.042)" stroke="white"/>
      <rect x="407.197" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 407.197 860.013)" stroke="white"/>
      <rect x="407.197" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 407.197 790.984)" stroke="white"/>
      <rect x="407.197" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 407.197 721.955)" stroke="white"/>
      <rect x="407.197" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 407.197 652.925)" stroke="white"/>
      <rect x="407.197" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 407.197 583.896)" stroke="white"/>
      <rect x="407.197" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 407.197 514.867)" stroke="white"/>
      <rect x="407.197" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 407.197 445.838)" stroke="white"/>
      <rect x="407.197" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 407.197 376.809)" stroke="white"/>
      <rect x="407.197" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 407.197 307.78)" stroke="white"/>
      <rect x="407.197" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 407.197 238.751)" stroke="white"/>
      <rect x="407.197" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 407.197 169.722)" stroke="white"/>
      <rect x="407.197" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 407.197 100.693)" stroke="white"/>
      <rect x="407.197" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 407.197 31.6636)" stroke="white"/>
      <rect x="469.713" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 469.713 929.042)" stroke="white"/>
      <rect x="469.713" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 469.713 860.013)" stroke="white"/>
      <rect x="469.713" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 469.713 790.984)" stroke="white"/>
      <rect x="469.713" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 469.713 721.955)" stroke="white"/>
      <rect x="469.713" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 469.713 652.925)" stroke="white"/>
      <rect x="469.713" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 469.713 583.896)" stroke="white"/>
      <rect x="469.713" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 469.713 514.867)" stroke="white"/>
      <rect x="469.713" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 469.713 445.838)" stroke="white"/>
      <rect x="469.713" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 469.713 376.809)" stroke="white"/>
      <rect x="469.713" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 469.713 307.78)" stroke="white"/>
      <rect x="469.713" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 469.713 238.751)" stroke="white"/>
      <rect x="469.713" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 469.713 169.722)" stroke="white"/>
      <rect x="469.713" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 469.713 100.693)" stroke="white"/>
      <rect x="469.713" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 469.713 31.6636)" stroke="white"/>
      <rect x="532.229" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 532.229 929.042)" stroke="white"/>
      <rect x="532.229" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 532.229 860.013)" stroke="white"/>
      <rect x="532.229" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 532.229 790.984)" stroke="white"/>
      <rect x="532.229" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 532.229 721.955)" stroke="white"/>
      <rect x="532.229" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 532.229 652.925)" stroke="white"/>
      <rect x="532.229" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 532.229 583.896)" stroke="white"/>
      <rect x="532.229" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 532.229 514.867)" stroke="white"/>
      <rect x="532.229" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 532.229 445.838)" stroke="white"/>
      <rect x="532.229" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 532.229 376.809)" stroke="white"/>
      <rect x="532.229" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 532.229 307.78)" stroke="white"/>
      <rect x="532.229" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 532.229 238.751)" stroke="white"/>
      <rect x="532.229" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 532.229 169.722)" stroke="white"/>
      <rect x="532.229" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 532.229 100.693)" stroke="white"/>
      <rect x="532.229" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 532.229 31.6636)" stroke="white"/>
      <rect x="594.746" y="929.042" width="69.0291" height="62.5162" transform="rotate(-90 594.746 929.042)" stroke="white"/>
      <rect x="594.746" y="860.013" width="69.0291" height="62.5162" transform="rotate(-90 594.746 860.013)" stroke="white"/>
      <rect x="594.746" y="790.984" width="69.0291" height="62.5162" transform="rotate(-90 594.746 790.984)" stroke="white"/>
      <rect x="594.746" y="721.955" width="69.0291" height="62.5162" transform="rotate(-90 594.746 721.955)" stroke="white"/>
      <rect x="594.746" y="652.925" width="69.0291" height="62.5162" transform="rotate(-90 594.746 652.925)" stroke="white"/>
      <rect x="594.746" y="583.896" width="69.0291" height="62.5162" transform="rotate(-90 594.746 583.896)" stroke="white"/>
      <rect x="594.746" y="514.867" width="69.0291" height="62.5162" transform="rotate(-90 594.746 514.867)" stroke="white"/>
      <rect x="594.746" y="445.838" width="69.0291" height="62.5162" transform="rotate(-90 594.746 445.838)" stroke="white"/>
      <rect x="594.746" y="376.809" width="69.0291" height="62.5162" transform="rotate(-90 594.746 376.809)" stroke="white"/>
      <rect x="594.746" y="307.78" width="69.0291" height="62.5162" transform="rotate(-90 594.746 307.78)" stroke="white"/>
      <rect x="594.746" y="238.751" width="69.0291" height="62.5162" transform="rotate(-90 594.746 238.751)" stroke="white"/>
      <rect x="594.746" y="169.722" width="69.0291" height="62.5162" transform="rotate(-90 594.746 169.722)" stroke="white"/>
      <rect x="594.746" y="100.693" width="69.0291" height="62.5162" transform="rotate(-90 594.746 100.693)" stroke="white"/>
      <rect x="594.746" y="31.6636" width="69.0291" height="62.5162" transform="rotate(-90 594.746 31.6636)" stroke="white"/>
      <rect x="-155.449" y="1205.16" width="1449.61" height="812.711" transform="rotate(-90 -155.449 1205.16)" stroke="white"/>
      </g>
      <g filter="url(#filter1_d_2054_95117)">
      <path d="M14.4311 208.482C20.8931 225.306 29.8225 240.223 45.3569 245.001C68.8183 252.223 93.309 234.633 97.6946 217.965C101.101 205.006 91.374 196.346 97.6407 184.272C101.448 176.94 110.254 170.09 118.364 171.12C129.933 172.587 129.503 188.739 144.858 199.838C158.778 209.896 181.811 213.016 194.014 202.548C207.122 191.304 204.489 167.165 204.154 164.074C202.961 153.164 199.083 148.363 200.017 135.41C200.409 129.988 201.235 118.486 207.247 116.205C212.847 114.083 220.188 121.006 225.76 126.375C243.848 143.812 240.975 154.831 252.323 161.98C266.059 170.634 289.96 166.879 299.675 153.286C306.362 143.929 304.715 134.346 300.916 123.703C294.845 106.708 289.801 92.533 287.064 84.6554C287.064 84.6554 272.461 45.8966 267.639 33.3407C263.26 21.9408 246.56 -20.7982 246.56 -20.7982C246.56 -20.7982 161.45 17.1085 102.939 37.012C44.4283 56.9155 -24.7434 82.6424 -28.364 86.6317C-31.9846 90.621 -29.8745 91.6518 -25.5744 102.847C-21.2744 114.043 14.4301 208.493 14.4301 208.493L14.4311 208.482Z" fill="#F37148"/>
      </g>
      </g>
      <g filter="url(#filter2_d_2054_95117)">
      <path d="M460.198 271.622C463.985 235.021 464.523 167.029 461.926 141.765C459.33 116.501 457.877 111.887 457.877 111.887C457.877 111.887 441.662 113.868 375.53 108.007C309.398 102.146 198.596 96.195 176.188 99.5788C153.786 102.969 130.622 105.574 130.622 105.574C129.848 159.301 129.066 213.021 128.291 266.748C128.291 266.748 120.846 413.306 123.795 420.236C126.745 427.166 437.718 438.509 446.289 433.323C454.859 428.137 458.132 418.269 458.279 410.634C458.426 402.999 461.407 328.174 462.34 319.79C463.272 311.405 456.397 308.215 460.184 271.614L460.198 271.622ZM356.422 298.171C339.612 343.782 240.175 345.12 222.838 294.812C214.764 271.378 225.781 240.443 237.746 223.666C263.238 187.944 311.502 190.559 337.81 224.81C350.472 241.29 365.18 274.384 356.415 298.17L356.422 298.171Z" fill="#7A77F4"/>
      </g>
      <g filter="url(#filter3_d_2054_95117)">
      <path d="M331.17 673.293L250.691 697.985C172.129 722.089 13.8566 771.695 13.8566 771.695C13.8566 771.695 102.093 1040.5 109.873 1059.02C117.653 1077.54 131.066 1073.43 131.066 1073.43C131.066 1073.43 212.56 1058.88 213.463 1048.15C214.365 1037.42 207.212 993.608 207.212 993.608L159.988 853.362L352.506 783.838C352.506 783.838 360.757 783.399 356.811 763.698C352.864 743.998 331.169 673.288 331.169 673.288L331.17 673.293Z" fill="#F37148"/>
      </g>
      <defs>
      <filter id="filter0_d_2054_95117" x="-188.677" y="-41.8931" width="871.24" height="1051.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="12.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2054_95117"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2054_95117" result="shape"/>
      </filter>
      <filter id="filter1_d_2054_95117" x="-40.2117" y="-26.7982" width="354.441" height="287.466" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2054_95117"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2054_95117" result="shape"/>
      </filter>
      <filter id="filter2_d_2054_95117" x="113.104" y="92.5932" width="360.427" height="356.047" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2054_95117"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2054_95117" result="shape"/>
      </filter>
      <filter id="filter3_d_2054_95117" x="3.85657" y="667.288" width="364.023" height="420.529" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2054_95117"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2054_95117" result="shape"/>
      </filter>
      </defs>
      </svg>
    </div>
    <ul class="primary">
      <li><router-link class="close" to="/">Home</router-link></li>
      <li><router-link class="close" to="/about">About</router-link></li>
      <li><router-link class="close" to="/introduction">Introduction</router-link></li>
      <li><router-link class="close" to="/letter-from-co-chairs">Letter from the Co-Chairs</router-link></li>
      <li><router-link class="close" to="/elders-message">Elder's Message</router-link></li>
      <li><router-link class="close" to="/developing-plan">Developing the Plan</router-link></li>
    </ul>
    <div class="bar"><hr><p>Priorities</p></div>
    <ul class="secondary">
      <li><router-link class="close" to="/drive-positive-impact">Drive Positive Impact</router-link></li>
      <li><router-link class="close" to="/pursue-sustainability">Pursue Environmental Sustainability</router-link></li>
      <li><router-link class="close" to="/innovate-learning">Innovate Learning, Teaching, and Research</router-link></li>
      <li><router-link class="close" to="/centre-student-journey">Centre the Student Journey</router-link></li>
      <li><router-link class="close" to="/decolonize-and-indigenize">Decolonize and Indigenize Art and Design Education and Advance Equity</router-link></li>
      <li><router-link class="close" to="/emerge-as-a-vibrant-hub">Emerge as a Vibrant Hub</router-link></li>
      <li><router-link class="close" to="/steward-financial-health">Steward Financial Health</router-link></li>
    </ul>
  </nav>
</template>

<script>
import methods from '@/assets/js/methods'
import { store } from '@/assets/js/store.js'

export default {
  name: "NavBar",
  props: {},
  data() {
    return {
      store
    }
  },
  methods: {
    pause() {
      store.playState = !store.playState;
      sessionStorage.setItem("playState", store.playState);

      const button = document.getElementById("pause-button")
      button.innerHTML = store.playState ? '\u25B6' : '||'
      
      const lotties = document.querySelectorAll('.lottie-animation-container.shape')
      for (const lottie of lotties) {
        const paused = sessionStorage.getItem("playState") === 'false';
        lottie.style.animationPlayState = paused ? 'running' : 'paused';
      }
    },
  },
  mounted() {
    this.js = methods.showMenu()
    this.js = methods.addCircleStrokes()
    this.js = methods.addUserwayMenu()
  },
};
</script>
    
<style scoped lang="scss">

.pause {
   display: inline-block;
  // position: absolute;
  // margin: 0 auto;
  // left: 0;
  // right: 0;
  // width: 140px;
}

p.button {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: ellograph-cf, monospace;
  text-transform: uppercase;
  
  .menu {
    position: absolute !important;
    top: 0.5rem;
    right: 1rem;
    cursor: pointer;
  }

  .pause {
    position: absolute !important;
    font-weight: 999;
    font-size: 0.8rem;
    top: 1.4rem;
    right: 6rem;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    text-align: center;
    border: 2px solid #000;
    cursor: pointer;

    &:hover {
      background: #000;
      color: $yellow;
    }
  }
}

.bar {
  margin: 3rem 0 2rem 0;
  padding: 0;
  position: relative;
  font-family: ellograph-cf, monospace;
  text-transform: uppercase;
  color: #666;
  font-size: 0.85rem;
  
  p {
    float: left;
    padding-right: 1rem;
    margin: -1.5rem 0 0 0;
    background-image: url('/src/assets/img/bg-noise.png');
    background-color: $white;
    background-blend-mode: multiply;
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      background: $red;
      margin-right: 0.5rem;
    }
  }
  hr {
    border: none;
    border-bottom: 1px #BBB solid;
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;

  .graphic {
    float: left;
    width: 35%;
    height: 100vh;
    margin-right: 4rem;
    box-shadow: 15px 0px 15px rgba(0, 0, 0, 0.1);

    svg {
      mix-blend-mode: multiply;
      width: 100%;
      height: 100vh;
    }
  }

  &.navbar {
    height: 88px;
    background: $yellow;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      
    &:after {
      content: '';
      position: absolute;
      height: 22px;
      left: 0;
      right: 0;
      top: 81px;
      background: $yellow;
      mix-blend-mode: multiply;
      opacity: 0.6;
      transform: matrix(1, 0, 0, 1, 0, 0);
      z-index:9;
    }
  }

  &.full {
    position: fixed;
    left: 100vw;
    height: 100vh;
    width: 100vw;
    background-image: url('/src/assets/img/bg-noise.png');
    background-color: $white;
    background-blend-mode: multiply;
    overflow: scroll;
    margin: 0;
    padding: 0 2rem 2rem 0;
    transition: all 0.6s !important;

    &.hide {
      left: 0;
    }
  }
}

#logo {
  margin: 30px 0;
  width: 85px;
  position: absolute;
  top: 0;
  left: 2rem;
}

ul {
  margin: 0;
  padding: 0;
  counter-reset: item;

  &.primary {
    margin-top: 6rem;
    li {
      margin: 0.75rem 0;
      list-style-type: none;
    }
    a {
      position: relative;
      padding-right: 3rem;
      text-transform: uppercase;
      font-weight: 700;
      text-decoration: none;
      font-size: 2rem;

      &:after {
        opacity: 0.0;
        content: '';
        position: absolute;
        right: 1rem;
        top: 0;
        display: block;
        background: url('../assets/img/arrow.png') right center no-repeat;
        background-size: contain;
        width: 2rem;
        height: 100%;
        transition: all 0.5s;
      }

      &:hover {
        &:after {
          opacity: .33;
          right: 0;
        }
      }
    }
  }

  &.secondary {
    li {
      padding: 0;
      margin: 0.75rem 0;
      list-style-type: none;
      counter-increment: item;
      &:before {
        font-size: 1rem;
        font-family: ellograph-cf, monospace;
        content: '0'counter(item)'. ';
        text-transform: uppercase;
        color: inherit;
        //margin-right: 1rem;
        text-align: right;
      }
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  p.button {
    .menu {
      top: 0;
      right: 0;
    }
    .pause {
      top: 0.9rem;
      right: 5rem;
    }    
  }
  #logo {
    margin: 22px 0;
    width: 65px;
    left: 1rem;
  }  
  nav.navbar {
    height: 66px;
    &:after {
      height: 16px;
      top: 59px;
    }
  }
  .graphic {
    display: none;
  }
  ul, .bar {
    margin-left: 2rem;
  }
  ul.primary {
    a {
      display: block;
      width: 100%;
    }
  }
}

</style>

