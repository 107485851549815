export default {
    addCircleStrokes: function() {
        const buttons = document.querySelectorAll('.button a.circle')
        for (const button of buttons) {
            if (!button.classList.contains('stroke')) {
                button.classList.add('stroke')
                const txt = button.innerHTML
                button.innerHTML = txt + '<svg viewBox="0 0 156 55" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1344 46.787C32.0098 50.6032 61.636 53.3972 76.6827 53.4954C105.4 53.6828 159.156 48.2181 153.601 26.2749C150.366 13.4972 125.826 1.49999 76.6828 1.5C26.4453 1.5 2.00001 15.5 2.00001 27.7362C2.00001 37 4.23522 50.6032 102.989 53.4954" stroke="#111111" stroke-width="3"/><path d="M22.1344 46.787C32.0098 50.6032 61.636 53.3972 76.6827 53.4954C105.4 53.6828 159.156 48.2181 153.601 26.2749C150.366 13.4972 125.826 1.49999 76.6828 1.5C26.4453 1.5 2.00001 15.5 2.00001 27.7362C2.00001 37 4.23522 50.6032 102.989 53.4954" stroke="black" stroke-opacity="0.2" stroke-width="3"/></svg>'
            }
        }
    },

    showMenu: function() {
        const toggles = document.querySelectorAll('.close')
        const menu = document.getElementById('menu')
        for (const toggle of toggles) {
            toggle.addEventListener("click", function() {
                menu.classList.toggle('hide')
            })
        }
    },

    addUserwayMenu: function() {

        var s = document.createElement("script");
        /* uncomment the following line to override default position*/
        s.setAttribute("data-position", 4);
    
        /* uncomment the following line to override default size (values: small, large)*/
        s.setAttribute("data-size", "small");
    
        /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
        /* s.setAttribute("data-language", "null");*/
    
        /* uncomment the following line to override color set via widget (e.g., #053f67)*/
        /* s.setAttribute("data-color", "#2d68ff");*/
    
        /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
        /* s.setAttribute("data-type", "1");*/
    
        /* s.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
        /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility";*/
    
        /* uncomment the following line to override support on mobile devices*/
        /* s.setAttribute("data-mobile", true);*/
    
        /* uncomment the following line to set custom trigger action for accessibility menu*/
        /* s.setAttribute("data-trigger", "triggerId")*/
        s.setAttribute("data-account", "Ki4Q0rYzCb");
        s.setAttribute("src", "https://cdn.userway.org/widget.js");
    
        (document.body || document.head).appendChild(s);
    
    }
}