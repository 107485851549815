<template>
  <NavBar />
  <router-view/>
  <Footer />
  <LandAcknowledgementView />
</template>

<script>
  // @ is an alias to /src
  import NavBar from '@/components/NavBar'
  import Footer from '@/components/Footer'
  import LandAcknowledgementView from '@/components/LandAcknowledgementView'
  
  export default {
    name: 'App',
    components: {
      NavBar,
      Footer,
      LandAcknowledgementView,
    },
  }
  </script>

<style lang="scss">
#app {
  position: relative;
}
</style>
