import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [{
        path: '/',
        name: 'home',
        component: () =>
            import ('../views/HomeView.vue'),
        meta: {
            title: 'OCADU Academic Strategic Plan',
            metaTags: [{
                name: 'description',
                content: 'OCAD Universities Academic Strategic Plan for 2022 - 2028'
            }]
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ('../views/AboutView.vue'),
        meta: {
            title: 'About',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/introduction',
        name: 'introduction',
        component: () =>
            import ('../views/IntroductionView.vue'),
        meta: {
            title: 'Introduction',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/letter-from-co-chairs',
        name: 'letter-from-co-chairs',
        component: () =>
            import ('../views/LetterFromCoChairsView.vue'),
        meta: {
            title: 'Letter From The Co-Chairs',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/elders-message',
        name: 'elders-message',
        component: () =>
            import ('../views/EldersMessageView.vue'),
        meta: {
            title: 'Elder\'s Message',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/developing-plan',
        name: 'developing-plan',
        component: () =>
            import ('../views/DevelopingPlanView.vue'),
        meta: {
            title: 'Developing A Plan',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/drive-positive-impact',
        name: 'drive-positive-impact',
        component: () =>
            import ('../views/PriorityOneView.vue'),
        meta: {
            title: 'Drive Positive Impact',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/pursue-sustainability',
        name: 'pursue-sustainability',
        component: () =>
            import ('../views/PriorityTwoView.vue'),
        meta: {
            title: 'Pursue Sustainability',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/innovate-learning',
        name: 'innovate-learning',
        component: () =>
            import ('../views/PriorityThreeView.vue'),
        meta: {
            title: 'Innovate Learning',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/centre-student-journey',
        name: 'centre-student-journey',
        component: () =>
            import ('../views/PriorityFourView.vue'),
        meta: {
            title: 'Centre the Student Journey',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/decolonize-and-indigenize',
        name: 'decolonize-and-indigenize',
        component: () =>
            import ('../views/PriorityFiveView.vue'),
        meta: {
            title: 'Decolonize and Indigenize',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/emerge-as-a-vibrant-hub',
        name: 'emerge-as-a-vibrant-hub',
        component: () =>
            import ('../views/PrioritySixView.vue'),
        meta: {
            title: 'Emerge As A Vibrant Hub',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },
    {
        path: '/steward-financial-health',
        name: 'steward-financial-health',
        component: () =>
            import ('../views/PrioritySevenView.vue'),
        meta: {
            title: 'Steward Financial Health',
            metaTags: [{
                name: 'description',
                content: ''
            }]
        },
    },

]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

// vue-router titles & metadata
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            tag.setAttribute('data-vue-router-controlled', '');
            return tag;
        })
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router